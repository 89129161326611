import React from "react";
import { Row, Container, Breadcrumb } from "react-bootstrap";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { isUserSignedIn } from "../../components/accountManagement";
import { SideBarOptions, adminMenuLinks, TopMenuOptions } from "../../components/genericMenu";
import { CrudTable } from "../../components/crudTable";
import { doesUserHaveRole } from "../../components/accountManagement";
import { FaCheck } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";

const uri = "/admin/users/";

const AdminUsersPage = ({ location }) => {
  const userData = useSelector((state) => state.userData);

  const UserRow = ({ data }) => {
    return (
      <>
        <td>{data.id}</td>
        <td>{data.is_active ? <FaCheck /> : <FcCancel />}</td>
        <td>{data.email}</td>
        <td>{data.display_name}</td>
        <td>{data.first_name}</td>
        <td>{data.last_name}</td>
        <td>{data.is_verified}</td>
        <td>{data.user_roles}</td>
        <td>{data.registered_on_site}</td>
        <td>{data.login_attempts}</td>
        <td>{data.locked_until_date}</td>
        <td>{data.last_login_date}</td>
        <td>
          {data.created_date}
          {data.created_date.substring(0, 19) === data.modified_date.substring(0, 19) ? (
            <></>
          ) : (
            <>
              <br />
              {data.modified_date}
            </>
          )}
        </td>
      </>
    );
  };

  return (
    <Layout pageInfo={{ pageName: "admin-users" }} sideBarOptions={SideBarOptions(adminMenuLinks, uri, "Admin")}>
      <Seo title="User Reviews" keywords={[`reviews`]} description="User Reviews" url={uri} />
      <Container fluid>
        <div id="top-menu">
          <TopMenuOptions menuLinks={adminMenuLinks} currentLink={uri} menuTitle="Admin" />
        </div>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Admin</Breadcrumb.Item>
            <Breadcrumb.Item active>Users</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Container>

      {isUserSignedIn(userData) && doesUserHaveRole(userData, "USER_ADMIN") ? (
        <CrudTable
          url="/users"
          urlParams=""
          customFilters={[
            { name: "search_text", param_name: "search_text", default: "" },
            { name: "is_active", param_name: "is_active", default: "ALL" },
            { name: "is_locked", param_name: "is_locked", default: "ALL" },
          ]}
          colHeaders={[
            "Id",
            "Active",
            "Email",
            "Display Name",
            "First Name",
            "Last Name",
            "Email Verified?",
            "Roles",
            "Registered on Site",
            "Login Attempts",
            "Locked Until Date",
            "Last Login Date",
            "Created Date/<br/>Modified Date",
          ]}
          rowComponent={(data) => {
            return <UserRow data={data} />;
          }}
          actionsAllowed={["delete-user", "edit"]}
          editColumns={[
            { name: "email", label: "Email", type: "text" },
            { name: "display_name", label: "Display Name", type: "text" },
            { name: "first_name", label: "First Name", type: "text" },
            { name: "last_name", label: "Last Name", type: "text" },
            {
              name: "locked_until_date",
              label: "Locked Until Date",
              type: "text",
            },
          ]}
          sortOptions={[
            { name: "Id", key: "asc" },
            { name: "Id desc", key: "desc" },
            { name: "Email", key: "email" },
            { name: "Email desc", key: "email-desc" },
            { name: "Name", key: "name" },
            { name: "Name desc", key: "name-desc" },
            { name: "Display Name", key: "display-name" },
            { name: "Display Name desc", key: "display-name-desc" },
            { name: "Login Attempts", key: "login-attempts" },
            { name: "Login Attempts desc", key: "login-attempts-desc" },
            { name: "Locked Until Date", key: "locked-until-date" },
            { name: "Locked Until Date desc", key: "locked-until-date-desc" },
            { name: "Created Date", key: "created-date" },
            { name: "Created Date desc", key: "created-date-desc" },
          ]}
        />
      ) : (
        <>Not logged in {navigate("/account/signin/")}</>
      )}
    </Layout>
  );
};

export default AdminUsersPage;
